<template>
  <div>
    <div style="display:flex;justify-content: space-between;">
      <button
        v-b-modal.create-material
        class="button"
        @click="loadOrdersTypes"
      >
        <span
          v-if="customDresses[0].formItem.length > 0 "
          class="number"
        >{{ customDresses[0].formItem.length }}</span>
        {{ $t('AddOrder') }}
      </button>
      <div
        v-if="materialId"
        style="display:flex;"
      >
        <div class="search-toggle">

          <p>{{ $t('SearchBy') }}</p>
          <div>
            <p
              :class="{ active2: toggleSearch === 'MaterialVariantName' }"
              @click="showVariantName(), toggleSearch = 'MaterialVariantName'"
            >
              {{ $t('MaterialVariantName') }}
            </p>
            <p
              :class="{ active2: toggleSearch === 'Color' }"
              @click="showColorName(), toggleSearch = 'Color'"
            >
              {{ $t('Color') }}
            </p>
            <p
              :class="{ active2: toggleSearch === 'Description' }"
              @click="showDescription(), toggleSearch = 'Description'"
            >
              {{ $t('Description') }}
            </p>
          </div>
        </div>
        <div v-if="toggleSearch === 'MaterialVariantName'">
          <div class="black">
            <b-icon-search
              v-if="searchMaterialVariant == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchMaterialVariant != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchMaterialVariant = ''"
            />
            <input
              v-model="searchMaterialVariant"
              :disabled="!materialId"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('MaterialVariantName')"
              :style="getMaterialVariantNames.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getMaterialVariantNames.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getMaterialVariantNames.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getMaterialVariantNames"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result.materialVariantName }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="toggleSearch === 'Color'">
          <div class="black">
            <b-icon-search
              v-if="searchColor == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchColor != '' && !noSearch1"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchColor = ''"
            />
            <input
              v-model="searchColor"
              :disabled="!materialId"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('Color')"
              :style="getMaterialColors.length > 0 && noSearch1 ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged1"
              @keydown.down="onArrowDown1"
              @keydown.up="onArrowUp1"
            >
          </div>
          <div
            v-if="getMaterialColors.length > 0 && noSearch1"
            ref="scrollContainer"
            class="dropdrop"
            :style="getMaterialColors.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getMaterialColors"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByCl(result); noSearch1 = false"
            >
              <p style="margin: 0">
                {{ result.colorName }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="toggleSearch === 'Description'">
          <div class="black">
            <b-icon-search
              v-if="searchDescription == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchDescription != '' && !noSearch2"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchDescription = ''"
            />
            <input
              v-model="searchDescription"
              :disabled="!materialId"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('Description')"
              :style="getFilterDescription.length > 0 && noSearch2 ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilterDescription.length > 0 && noSearch2"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilterDescription.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilterDescription"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByDs(result); noSearch2 = false"
            >
              <p style="margin: 0">
                {{ result.materialVariantName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <MaterialVariant
        :material-id="materialId"
        :measurement-type="measurementType"
        @objectMaterial="updateCustomDresses"
        @objectMaterialDescription="updateCustomDressesDescription"
      />
    </div>
    <CreateOrder
      :dress="customDresses"
      @addOrderNominative="onSubmitOrder"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateOrder from './CreateOrder.vue'
import MaterialVariant from './MaterialVariant.vue'

export default {
  components: {
    CreateOrder,
    MaterialVariant,
  },
  props: ['measurementType', 'materialId', 'customNewMaterials'],
  data() {
    return {
      page: 1,
      pageSize: 15,
      toggleSearch: 'MaterialVariantName',
      searchMaterialVariant: '',
      searchColor: '',
      searchDescription: '',
      noSearch1: false,
      noSearch: false,
      noSearch2: false,
      arrowCounter: 0,
      customDresses: [
        {
          paidPrice: 0,
          price: 0,
          formItem: [],
          showLengthError: false,
          isEmpty: true,
          duplicateMV: false,

        },
      ],
    }
  },
  computed: {
    ...mapGetters(['getOrderTypes', 'getLoggedInUser',
      'getMaterialVariantPDFQRCode',
      'getInventoryMaterialVariantsId',
      'getMaterialColors',
      'getTotalPriceByMaterialId',
      'getIsInCart',
      'getMaterialVariantById',
      'getMaterialVariantHistoryCurrentPage',
      'getMaterialVariantHistoryTotalItems',
      'getMaterialVariantHistory',
      'getLanguage',
      'getMaterialVariantNames',
      'getMaterialVariant',
      'getMatVarTotalItems',
      'getMatVarCurrentPage',
      'getImagesProduct',
      'getFilterDescription']),
  },
  watch: {
    customNewMaterials(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.customDresses[0].formItem.push(newValue);
      }
    },
    searchMaterialVariant(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadMaterialVariant({
            id: this.materialId,
            pageNumber: this.page,
            pageSize: this.pageSize,
            successCallback: () => {},
          });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.materialVariantNameLike({
            materialName: value,
            materialId: this.materialId,
            measurementType: null,
          });
          this.noSearch = true
        }
      }, 500);
    },
    searchColor(value) {
      console.log('value', value)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadMaterialVariant({
            id: this.materialId,
            pageNumber: this.page,
            pageSize: this.pageSize,
            successCallback: () => {},
          });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.materialColorNameLike(value);
          this.noSearch1 = true
        }
      }, 500);
    },
    searchDescription(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeout);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadMaterialVariant({
            id: this.materialId,
            pageNumber: this.page,
            pageSize: this.pageSize,
            successCallback: () => {},
          });
        } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.variantsByDescription({
            descriptionName: value,
            materialId: this.materialId,
            measurementType: this.measurementType,
          })
          this.noSearch2 = true
        }
      }, 500)
    },
  },
  methods: {
    ...mapActions([
      'loadOrderTypes', 'accountDetails',
      'getMaterialVariantsToMDCPagination',
      'addOrderInNominative', 'isAlreadyInCart',
      'loadMaterialVariant',
      'materialVariantNameLike',
      'resetMaterialVariantNames',
      'addMaterialVariant',
      'editMaterialVariantReq',
      'removeMaterialVariant',
      'addStock',
      'materialVariantHistoryTrackPagination',
      'subStock',
      'loadSuppliers',
      'loadColors',
      'changeLoadingtoTrue',
      'getImagesByMaterialVariantId',
      'locationPagination',
      'addMaterialVariantToCart',
      'loadCartItems',
      'getMaterialVariantById_new',
      'editMaterialVariantById_new',
      'materialColorNameLike',
      'loadInventoryMaterialVariantsId',
      'loadMaterialVariantsPDFQRCode',
      'variantsByDescription',
      'usersByMultipleRoles',
      'createdMaterialOrders',
    ]),
    showVariantName() {
      this.toggleSarch = false;
      this.searchColor = '';
      this.toggleSearch = 'MaterialVariantName';
      this.noSearch = false
      this.noSearch1 = false
      this.noSearch2 = false
      this.loadMaterialVariant({
        id: this.materialId,
        pageNumber: this.page,
        pageSize: this.pageSize,
      });
    },
    showColorName() {
      this.noSearch = false
      this.noSearch1 = false
      this.noSearch2 = false
      this.toggleSarch = true;
      this.searchMaterialVariant = '';
      this.toggleSearch = 'Color';
      this.loadMaterialVariant({
        id: this.materialId,
        pageNumber: this.page,
        pageSize: this.pageSize,
      });
    },
    showDescription() {
      this.noSearch1 = false
      this.noSearch = false
      this.noSearch2 = false
      this.toggleSearch = 'Description';
      this.searchDescription = ''
      this.loadMaterialVariant({
        id: this.materialId,
        pageNumber: this.page,
        pageSize: this.pageSize,
      });
    },
    loadOrdersTypes() {
      this.accountDetails({
        isActive: true,
      })
      this.loadOrderTypes()
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
      if (this.customDresses[0].formItem.length > 0) {
        this.getMaterialVariantsToMDCPagination({
          pageNumber: 1,
          pageSize: 15,
          measurement: 'Set',
        })
        this.getMaterialVariantsToMDCPagination({
          pageNumber: 1,
          pageSize: 15,
          measurement: 'Pair',
        })
        this.getMaterialVariantsToMDCPagination({
          pageNumber: 1,
          pageSize: 15,
          measurement: 'Package',
        })
        this.getMaterialVariantsToMDCPagination({
          pageNumber: 1,
          pageSize: 15,
          measurement: 'Verse',
        })
      }
      if (this.measurementType) {
        this.getMaterialVariantsToMDCPagination({
          pageNumber: 1,
          pageSize: 15,
          measurement: this.measurementType ? this.measurementType : null,
        })
      }

      this.usersByMultipleRoles({
        status: true,
        object: ['SalesPerson', 'OnlineSales'],
      })
    },
    onSubmitOrder(value) {
      this.addOrderInNominative({
        object: value,
        successCallback: () => {
          this.createdMaterialOrders({
            pageNumber: 1,
            pageSize: 15,
            orderNumber: null,
            clientId: null,
          })
        },
      });
    },
    updateCustomDresses(item) {
      this.customDresses[0].formItem.push(item);
    },
    updateCustomDressesDescription(item) {
      this.customDresses[0].formItem.push(item);
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredMaterials = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getMaterialVariantNames[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    inputChanged1(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredMaterials = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCl(this.getMaterialColors[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    async searchByOn(value) {
      this.searchMaterialVariant = value.materialVariantName
      this.searchId = value.materialVariantId
      this.noSearch = false
      await this.loadMaterialVariant({
        id: this.materialId,
        materialVariantId: this.searchId,
        pageNumber: 1,
        pageSize: this.pageSize,
        successCallback: () => {},
      });
    },
    async searchByCl(value) {
      this.searchColor = value.colorName
      this.searchId = value.colorId
      this.noSearch1 = false
      await this.loadMaterialVariant({
        id: this.materialId,
        colorId: this.searchId,
        pageNumber: 1,
        pageSize: this.pageSize,
        successCallback: () => {},
      });
      this.noSearch1 = false
    },
    async searchByDs(value) {
      this.searchDescription = value.materialVariantName
      this.searchId = value.materialVariantId
      this.noSearch2 = false
      await this.loadMaterialVariant({
        id: this.materialId,
        materialVariantId: this.searchId,
        pageNumber: 1,
        pageSize: this.pageSize,
        successCallback: () => {},
      });
      this.noSearch2 = false
    },
    onArrowDown1(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getMaterialColors.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp1(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getMaterialVariantNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    removeLists() {
      this.noSearch = false;
      this.resetMaterialVariantNames();
    },
  },
}
</script>


<style>
.number {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: red;
  color: white;
  position: relative;
  bottom: 50%;
  left: 75%;
  border:1px solid white;
}
.team_table td {
  padding: 12px 9px !important;
}

.team_table th {
  /* text-align: center; */
}

.buttonSubmiti:hover{
  border: 2px solid #FF274F;
  background: white;
  color: #FF274F;
  transition: ease-in-out 0.3s;
  height: 38px;
  width: 50px;
}

.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.dropdrop::-webkit-scrollbar {
  display: none;
}

.team_table td {
  padding: 9px 9px !important;
}
.active2 {
  font-weight: bold;
  color: #212529;
}

thead tr th {
    background: #f4f5f6 !important;
    color: #475467 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
.popup {
  display: flex;
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 9999;
}
.button {
  width: 195px;
}
.variant-table {
  margin-top: 72px;
}
.orange-color {
  color: orange !important;
}
.red-color {
  color: red !important;
}
.green-color {
  color: green !important;
}

tr:last-child {
  border-bottom: none !important;
}
.test {
  flex-grow: 0.5;
  margin-top: -15px;
  margin-left: 10px;
}
@media screen and (max-width: 1200px) {
  .filtering {
    position: fixed;
    top: 1%;
    left: 30% !important;
  }
}
</style>
