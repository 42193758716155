<template>
  <div
    class="materials"
    @click="removeLists"
  >
    <div
      class="searchfilter"
      style="display: flex;justify-content: space-between;"
    >
      <div style="display: flex; align-items: center">
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchMaterial == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchMaterial != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchMaterial = ''"
            />
            <input
              v-model="searchMaterial"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('MaterialName')"
              :style="getFilteredMaterials.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilteredMaterials.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilteredMaterials.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilteredMaterials"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result }}
              </p>
            </div>
          </div>
        </div>
        <b-form-select
          v-model="selected"
          style="padding: 8.5px; color:#82868c; margin-left: 20px; border: 1px solid #e2e2e2; border-radius: 5px;"
          dropup
          :options="options"
        />
      </div>
    </div>

    <div
      class="tt"
      style="margin-top:15px"
    >
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th style="text-align:left;">
              {{ $t('No') }}
            </th>
            <th
              style="cursor: pointer;text-align:left;"
              @click="sortByName"
            >
              {{ $t('MaterialName') }}
              <b-icon-sort-alpha-down v-if="sorted == false" />
              <b-icon-sort-alpha-up-alt v-else />
            </th>
            <th style="text-align:left;">
              {{ $t('Description') }}
            </th>
            <th style="text-align:left;">
              {{ $t('MeasurementType') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for=" (material,index) in getMaterials"
            :key="material.materialId"
            class="hover-row"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor:pointer;"
            :style="classNameCh === material.materialId ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(material)"
          >
            <td>
              {{ getTotalItemsForMaterials - (page - 1) * pageSize - index }}.
            </td>
            <td>
              {{ material.name }}
            </td>
            <td>
              {{ material.description }}
            </td>

            <td>
              {{ formatMeasurementType(material.measurementType) }}
            </td>
          </tr>
        </tbody>

      </table>

    </div>
    <div>
      <b-pagination
        v-if="getTotalItemsForMaterials > 20"
        v-model="page"
        :total-rows="getTotalItemsForMaterials"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        size="md"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>

    <AddRowModal
      :item="item"
      @materialvariant="addMaterialV"
    />

    <EditMaterial
      :edit-m="editMat"
      :add-edit="addEdit"
      @materialsEdit="updateMaterial"
      @materialsAdd="materialsData"
    />
  </div>
</template>

<script>
import EditMaterial from '@/modals/AddEditMaterial.vue'

import AddRowModal from '@/modals/AddRowModal.vue'

// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    EditMaterial,
    AddRowModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['rowsLengthData'],
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: this.$t('All') },
        { value: '0', text: this.$t('Meter') },
        { value: '1', text: this.$t('Part') },
        { value: '2', text: this.$t('Package') },
        { value: '3', text: this.$t('BeadedStrand') },
        { value: '4', text: this.$t('Set') },
        { value: '5', text: this.$t('Pair') },
      ],
      addEdit: false,
      sorted: false,
      // initialId: '',
      materialPropId: '',
      classNameCh: '',
      editMat: {
        materialId: '',
        name: '',
        description: '',
        measurementType: '',
        created: '',
      },
      item: {
        materialId: '',
        name: '',
        measurementType: '',
      },
      page: 1,
      pageSize: 15,
      noSearch: false,
      searchMaterial: '',
      arrowCounter: 0,
      enterPressed: false,

    }
  },
  computed: {
    ...mapGetters(['getFilteredMaterials', 'getMaterials', 'getIsLoading', 'getCurrentPageForMaterials', 'getTotalItemsForMaterials', 'getMatVarCurrentPage', 'getTotalPriceByMaterialId']),
  },
  watch: {
    // initialId() {
    //   this.immediateId(this.initialId)
    // },
    page(value) {
      this.loadMaterials({
        pageNumber: value,
        pageSize: this.pageSize,
        materialName: this.searchMaterial,
        measurementType: this.selected == null ? 'ALL' : this.selected,
      })
    },
    searchMaterial(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadMaterials({
            pageNumber: this.getCurrentPageForMaterials,
            pageSize: this.pageSize,
            materialName: this.searchMaterial,
            measurementType: this.selected == null ? 'ALL' : this.selected,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filteredMaterialsByName({
            materialName: value,
            measurementType: this.selected == null ? null : this.selected,
          });
          this.noSearch = true
        }
      }, 500);
    },
    selected(value) {
      this.loadMaterials({
        pageNumber: this.getCurrentPageForMaterials,
        pageSize: this.pageSize,
        materialName: this.searchMaterial,
        measurementType: value == null ? 'ALL' : value,
      })
      this.searchMaterial = '';
      this.resetMaterialVariant()
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions(['totalPriceByMaterialId', 'resetMaterialVariant', 'filteredMaterialsByName', 'addMaterial', 'loadMaterials', 'editMaterialReq', 'loadMaterialVariant', 'addMaterialVariant', 'changeLoadingtoTrue', 'resetFilteredMaterials']),
    formatMeasurementType(value) {
      let name
      if (value === '0') {
        name = this.$t('Meter')
      } else if (value === '1') {
        name = this.$t('Part')
      } else if (value === '2') {
        name = this.$t('Package')
      } else if (value === '3') {
        name = this.$t('BeadedStrand')
      } else if (value === '4') {
        name = this.$t('Set')
      } else if (value === '5') {
        name = this.$t('Pair')
      } else if (value === 'Meter') {
        name = this.$t('Meter')
      } else if (value === 'Part') {
        name = this.$t('Part')
      } else {
        name = value
      }
      return name
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)

        await this.loadMaterials({
          pageNumber: this.page,
          pageSize: this.pageSize,
          materialName: this.searchMaterial,
          measurementType: this.selected == null ? 'ALL' : this.selected,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredMaterials.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredMaterials = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredMaterials[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    editM(item) {
      this.addEdit = false
      this.editMat.materialId = item.materialId
      this.editMat.name = item.name
      this.editMat.description = item.description
      this.editMat.measurementType = item.measurementType
      this.editMat.created = item.created
      this.$bvModal.show('modal-edit-mat')
    },
    async updateMaterial(obj) {
      await this.editMaterialReq({
        object: obj,
        successCallback: () => {
          this.loadMaterials({
            pageNumber: this.getCurrentPageForMaterials,
            pageSize: this.pageSize,
            materialName: this.searchMaterial,
            measurementType: this.selected == null ? 'ALL' : this.selected,
          })
        },
      })
      this.$bvModal.hide('modal-edit-mat')
    },

    async addMaterialV(obj) {
      // console.log(location)

      await this.addMaterialVariant({
        object: obj,
        successCallback: () => {
          this.loadMaterialVariant({
            id: this.materialPropId,
            pageNumber: 1,
            pageSize: this.pageSize,
            successCallback: () => {

            },
          })
        },
      })
    },
    sortByName() {
      if (this.sorted == false) {
        this.getMaterials.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        this.sorted = true
      } else {
        this.getMaterials.sort((a, b) => {
          return a.name > b.name ? -1 : 1
        })
        this.sorted = false
      }
    },
    getmaterial(mat) {
      this.item.materialId = mat.materialId
      this.item.measurementType = mat.measurementType
      this.item.name = mat.name
    },
    async materialsData(obj) {
      await this.addMaterial({
        object: obj,
        successCallback: () => {
          this.loadMaterials({
            pageNumber: 1,
            pageSize: this.pageSize,
            materialName: this.searchMaterial,
            measurementType: this.selected == null ? 'ALL' : this.selected,
          })
          this.page = 1
        },
      })
      this.editMat.materialId = ''
      this.editMat.name = ''
      this.editMat.description = ''
      this.editMat.measurementType = ''
      this.editMat.created = ''
      this.$bvModal.hide('modal-edit-mat')
    },
    resetObject() {
      this.addEdit = true
      this.$bvModal.show('modal-edit-mat')
      this.editMat.materialId = ''
      this.editMat.name = ''
      this.editMat.description = ''
      this.editMat.measurementType = ''
      this.editMat.created = ''
    },
    // immediateId(e) {
    //   this.$emit('click', e)
    // },
    matProps(mat) {
      // this.changeLoadingtoTrue(false)

      this.materialPropId = mat.materialId
      this.$emit('sendMaterial', mat)
      this.classNameCh = mat.materialId
      this.loadMaterialVariant({
        id: mat.materialId,
        pageNumber: 1,
        pageSize: this.pageSize,
        successCallback: () => {
          // this.changeLoadingtoTrue(true)
        },
      })
      this.totalPriceByMaterialId(
        mat.materialId,
      )
      this.getmaterial(mat)
    },
    async searchByOn(value) {
      this.searchMaterial = value
      this.noSearch = false
      await this.loadMaterials({
        pageNumber: this.getCurrentPageForMaterials,
        pageSize: this.pageSize,
        materialName: this.searchMaterial,
        measurementType: this.selected == null ? 'ALL' : this.selected,
      })
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredMaterials();
    },
  },
}
</script>

<style scoped lang="scss">
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.mats-listing__wrapper {
  width: 600px !important;

  .tt {
    .vgt-table {
      th.sortable button {
        display: none !important;
      }
    }
  }

  .mats-listing {
    margin-top: 0;
  }
}

.button {
  margin-bottom: 15px !important;
  // width: 195px;
}

.team_table td {
  padding: 16px 9px !important;
  text-align: left;
}

// .team_table .tr{
//   border-radius: 10px 10px 10px 10px !important;
// }
tr:last-child {
  border-bottom: none !important;
}

.materials {
  flex-grow: 0.44;
  margin-top: 0px;
  width: 60% !important;
}

.dropdrop::-webkit-scrollbar {
  display: none;

}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}

@media screen and (max-width: 1723px) {
  .mats-listing__wrapper {
    width: 400px !important;

    div {
      button {
        width: auto;
        height: 36px;
      }

      input {
        width: 160px;
      }

    }

    .table__search-input {
      padding-left: 0px;
      margin-left: 0px !important;
    }
  }

}

@media screen and (max-width: 1400px) {
  .mats-listing__wrapper {
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  .materials {
    flex-grow: 0.44;
    margin-top: 0px;
    width: 100% !important;
  }
}

// @media screen and (min-width: 1300px) and (max-width:1400px){
//   .team_table {
//     width: 94%;
//   }
// }
</style>
