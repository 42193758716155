<template>
  <div @click="removeLists">
    <div
      class="filtering"
      style="display: flex;justify-content: space-between;"
    >
      <div style="display: flex; align-items: center">
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchByDescription == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchByDescription != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchByDescription = ''"
            />
            <input
              v-model="searchByDescription"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('MaterialName')"
              :style="getFilterDescriptions.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilterDescriptions.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilterDescriptions.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilterDescriptions"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result.materialVariantName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <table class="team_table">
        <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
          <tr style="">
            <th>
              {{ $t("Variant") }}
            </th>
            <th>
              {{ $t("Description") }}
            </th>
            <th>
              {{ $t('Image') }}
            </th>
            <th>
              {{ $t("Color") }}
            </th>
            <th
              style="cursor: pointer;"
              @click="minimalPrice"
            >
              {{ $t("Price") }}
              <b-icon-sort-numeric-down v-if="sortedTwo == false" />
              <b-icon-sort-numeric-up v-if="sortedTwo == true" />
            </th>
            <th
              @click="minimalStock"
            >
              {{ $t("Amount") }}
              <b-icon-sort-numeric-down v-if="sorted == false" />
              <b-icon-sort-numeric-up v-if="sorted == true" />
            </th>
            <!-- <th
                style="max-width: 84px;
                text-align: center;"
              >
                {{ $t("SubstractStock") }}
              </th> -->
            <th>
              {{ $t("MainSupplier") }}
            </th>
            <th
              v-if="client !='DrenushaXharra'"
            >
              {{ $t("Location") }}
            </th>
            <th style="text-align:left;">
              {{ $t('AddToOrder') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in getMaterialVariant"
            :key="index"
          >
            <td
              :style="item.materialVariantName.length >= 20 ? 'width: 20%' : ''"
            >
              {{ item.materialVariantName }}
            </td>
            <td>
              {{ item.description }}
            </td>
            <td
              v-if="item.photos[0]"
              style="display:flex;gap:3px;"
            >
              <template v-if="item.photos[0].endsWith('.pdf') || item.photos[0].endsWith('.docx') || item.photos[0].endsWith('.xlsm') || item.photos[0].endsWith('.xlsx') || item.photos[0].endsWith('.plain')">
                <img
                  v-b-tooltip="$t('Document')"
                  style="width:30px;height:30px"
                  src="../../assets/images/documentLogo.svg"
                >
                <button
                  v-if="item.photos.length > 0"
                  v-b-tooltip="$t('Download')"
                  style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                  @click.stop="downloadFile(item.photos[0])"
                >
                  <b-icon-download style="color: #FF274F;" />
                </button>
              </template>
              <template v-else>
                <img
                  v-b-tooltip="$t('ClickToViewPhoto')"
                  style="width:30px;height: 30px; border-radius: 3px; "
                  :src="item.photos[0]"
                  alt="No image"
                  @click.stop="getAllImages(item)"
                >
                <button
                  v-if="item.photos.length > 0"
                  v-b-tooltip="$t('Download')"
                  style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                  @click.stop="downloadFile(item.photos[0])"
                >
                  <b-icon-download style="color: #FF274F;" />
                </button>
              </template>
            </td>
            <td v-else>
              <img
                v-b-tooltip="$t('NoImageOrDocument')"
                style="width:30px;height:30px"
                src="../../assets/images/noImageAvailable.svg"
              >
            </td>
            <td>
              {{ item.colorName }}
            </td>
            <td>
              {{ numbersWithcomma(item.pricePerMeter) }}&euro;
            </td>

            <td
              :class="
                minimalColor(
                  item.minimalQuantityStock,
                  item.currentAmount
                )
              "
            >
              {{ item.currentAmount }}
            </td>
            <td>
              {{ item.supplierName }}
            </td>
            <td
              v-if="client !='DrenushaXharra'"
            >
              {{ item.locationName }}
            </td>
            <td
              v-b-modal.material-to-standard-description
              style="cursor:pointer"
              @click.stop="callModal(item)"
            >
              <button
                style="width:150px"
                class="button"
              >
                {{ $t('AddToOrder') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddToOrderStandardDescription
      :material-variant-name-add="materialVariantNameAdd"
      @onSubmit="addToOrder"
    />
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { client } from '@/domainConfig'
import VueEasyLightbox from 'vue-easy-lightbox'
import AddToOrderStandardDescription from './AddToOrderStandardDescription.vue'


export default {
  components: {
    VueEasyLightbox,
    AddToOrderStandardDescription,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: [],
  data() {
    return {
      materialVariantNameAdd: null,
      client: client.clientName,
      sorted: false,
      sortedTwo: false,
      page: 1,
      pageSize: 15,
      noSearch: false,
      searchByDescription: '',
      arrowCounter: 0,
      enterPressed: false,
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
    }
  },
  computed: {
    ...mapGetters(['getFilterDescriptions', 'getMaterialVariant', 'getMaterials', 'getIsLoading', 'getCurrentPageForMaterials', 'getTotalItemsForMaterials', 'getMatVarCurrentPage', 'getTotalPriceByMaterialId']),
  },
  watch: {
    searchByDescription(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadMaterialVariants({
            id: null,
            materialVariantId: null,
            colorId: null,
            pageNumber: 1,
            pageSize: 15,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filterByDescription({
            descriptionName: value,
            materialId: null,
          });
          this.noSearch = true
        }
      }, 500);
    },

  },
  mounted() {
  },
  methods: {
    ...mapActions(['totalPriceByMaterialId', 'filterByDescription', 'resetMaterialVariant', 'filteredMaterialsByName', 'addMaterial', 'loadMaterialVariants', 'editMaterialReq', 'loadMaterialVariants', 'addMaterialVariant', 'changeLoadingtoTrue', 'resetFilteredMaterials']),
    addToOrder(value) {
      let quantity = 0
      let length = 0
      if (value.measurementType == 'Part') {
        quantity = value.amount
      } else {
        length = value.amount
      }
      const name = {
        materialVariantId: value.materialVariantId,
        materialVariantName: value.materialVariantName,
        pricePerMeter: value.price,
        priceWithDiscount: value.priceWithDiscount,
      }
      const objekti = {
        materialVariantId: '',
        measurementType: value.measurementType,
        name,
        quantity,
        length,
        price: 0,
      }
      this.$emit('objectMaterialDescription', objekti);
    },
    callModal(value) {
      this.materialVariantNameAdd = value
    },
    minimalColor(min, actual) {
      const oneThird = 0.5 * min + min;

      if (actual <= min) {
        return 'red-color';
      }
      if (actual >= min && actual <= oneThird) {
        return 'orange-color';
      }
      return 'green-color';
    },
    async getAllImages(item) {
      // Set the array to an empty state at the beginning
      this.image.imgs = [];

      // Filter images based on the materialVariantId
      const filteredImages = this.getMaterialVariant.filter((image) => {
        return image.materialVariantId === item.materialVariantId;
      });
      // Fetch and push the filtered images
      for (let i = 0; i < filteredImages.length; i++) {
        const image = filteredImages[i];
        try {
          // Access all photos in the 'image.photos' array and push them to 'this.image.imgs'
          // eslint-disable-next-line no-restricted-syntax
          for (const photo of image.photos) {
            this.image.imgs.push({
              src: photo, // Assign the fetched image
              // materialVariantPhotoId: image.materialVariantPhotoId,
              // fileName: image.fileName,
              // fileType: image.fileType,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }

      // Continue with your other logic
      this.show();
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    downloadFile(fileUrl) {
      // Extract filename from the URL
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;

      // Trigger a click on the anchor element to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    minimalStock() {
      if (this.sorted == false) {
        this.getMaterialVariant.sort((a, b) => {
          return (
            a.currentAmount
            - b.currentAmount
          );
        });
        this.sorted = true;
      } else {
        this.getMaterialVariant.sort((a, b) => {
          return (
            b.currentAmount
            - a.currentAmount
          );
        });
        this.sorted = false;
      }
    },
    minimalPrice() {
      if (this.sortedTwo == false) {
        this.getMaterialVariant.sort((a, b) => {
          return a.pricePerMeter - b.pricePerMeter;
        });
        this.sortedTwo = true;
      } else {
        this.getMaterialVariant.sort((a, b) => {
          return b.pricePerMeter - a.pricePerMeter;
        });
        this.sortedTwo = false;
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilterDescriptions.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilterDescriptions[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    async searchByOn(value) {
      this.searchByDescription = value.materialVariantName
      this.noSearch = false
      await this.loadMaterialVariants({
        id: null,
        materialVariantId: value.materialVariantId,
        colorId: null,
        pageNumber: 1,
        pageSize: 15,
      })
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredMaterials();
    },
  },
}
</script>
