<template>
  <div>
    <b-modal
      id="material-to-standard"
      ref="modal"
      header-class="headerModal"
      centered
      size="lg"
      :title="$t('AddToOrder')"
      hide-footer
    >

      <b-form>
        <div style="display:flex;gap:10px">
          <b-form-group
            :label="$t('Variant')"
            style="width:100%"
          >
            <b-form-input
              v-model="form.materialVariantName"
              disabled
            />
          </b-form-group>
          <b-form-group
            :label="$t('Real Price')"
            style="width:100%"
          >
            <b-form-input
              v-model="form.price"
              type="number"
              disabled
            />
          </b-form-group>
        </div>
        <div style="display:flex;gap:10px">
          <b-form-group
            :label="$t('PriceWithDiscount')"
            style="width:100%"
          >
            <b-form-input
              v-model="form.priceWithDiscount"
              type="number"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Amount')"
            style="width:100%"
          >
            <b-form-input
              v-model="form.amount"
              type="number"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-group
            :label="$t('TotalStockAmount')"
            style="width:48%"
          >
            <b-form-input
              v-model="form.currentAmount"
              type="number"
              disabled
            />
          </b-form-group>
        </div>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-left: 5px; background: #FF274F !important;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'OrderNominative',
  components: {
  },
  props: ['materialVariantNameAdd'],
  data() {
    return {
      form: {
        materialVariantName: null,
        amount: 0,
        price: 0,
        materialVariantId: null,
        currentAmount: null,
        priceWithDiscount: 0,
      },
    }
  },
  computed: {

  },
  watch: {
    materialVariantNameAdd(value) {
      this.form.materialVariantName = value.materialVariantName
      this.form.price = value.pricePerMeter
      this.form.priceWithDiscount = value.pricePerMeter
      this.form.materialVariantId = value.materialVariantId
      this.form.currentAmount = value.currentAmount
    },
  },
  methods: {
    onSubmit() {
      this.$emit('onSubmit', this.form)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
      }, 10)
      this.form.amount = 0
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
      }, 10)
      this.form.amount = 0
    },
  },
}
</script>
