<template>
  <div>
    <section
      class="main1"
      style="gap:10px;"
    >
      <b-tabs
        v-model="tabIndex"
      >
        <b-tab @click="resetMaterialsAndCallMethod">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Materials") }}</strong>
          </template>
          <div
            class="tables"
          >
            <Materials
              @sendMaterial="sentMeasurementType"
            />
            <CreateOrderInNominative
              style="width:100%"
              :measurement-type="measurementType"
              :material-id="materialId"
              :custom-new-materials="customNewMaterials"
            />
          </div>

        </b-tab>
        <b-tab
          style="display:flex;gap:10px;padding-top: 15px;"
          @click="resetMaterials"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("FilterByDescription") }}</strong>
          </template>
          <DescriptionMaterials
            v-if="tabIndex == 1"
            @objectMaterialDescription="updateOrders"
          />
        </b-tab>
      </b-tabs>

    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import CreateOrderInNominative from '@/components/materialsNominvative/CreateOrderInNominative.vue'
import Materials from '@/components/materialsNominvative/Materials.vue'
import DescriptionMaterials from '@/components/materialsNominvative/DescriptionMaterials.vue'
import { mapActions } from 'vuex'

export default {
  name: 'OrderNominative',
  components: {
    CreateOrderInNominative,
    Materials,
    DescriptionMaterials,
  },
  data() {
    return {
      measurementType: null,
      materialId: null,
      tabIndex: 0,
      customNewMaterials: null,
    }
  },
  computed: {

  },
  update() {
    this.sentMeasurementType()
  },
  watch: {

  },
  methods: {
    ...mapActions(['resetMaterialVariant', 'loadMaterials']),
    updateOrders(value) {
      this.customNewMaterials = value
    },
    sentMeasurementType(value) {
      this.measurementType = value.measurementType
      this.materialId = value.materialId
    },
    resetMaterials() {
      this.resetMaterialVariant()
    },
    resetMaterialsAndCallMethod() {
      this.loadMaterials({
        pageNumber: 1,
        pageSize: 15,
        materialName: null,
        measurementType: this.selected == null ? 'ALL' : this.selected,
      })
      this.resetMaterialVariant()
    },
  },
}
</script>

<style>
.main1{
  padding: 75px 15px 15px 120px;
}

.tables {
  display:flex !important;
  gap:10px;
  padding-top: 15px;
}

@media screen and (max-width: 1200px) {
  .tables {
    display:flex !important;
    flex-direction: column;
    gap:10px;
    padding-top: 15px;
  }
  .main1{
    padding: 75px 15px 15px 20px;
  }
}

</style>
